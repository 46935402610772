import React from "react";

function Welcome() {
  return (
    <main className={"landing"}>
      <h1>Bine ai Venit la Profesori de Sprijin</h1>
      <h2>Informații Despre Serviciile de Sprijin Educațional</h2>
    </main>
  );
}

export default Welcome;
